<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('FILTER')"
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
        title="Mentés"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Szűrő</h1>

    <v-expansion-panels class="mb-4" mandatory disabled>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="5" :md="4">
              <v-select
                label="Típus"
                v-model="elementTypeId"
                :items="elementTypes"
                item-text="name"
                item-value="id"
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="elementTypeId">
      <v-alert border="left" colored-border type="warning" elevation="2">
        <b>Figyelem!</b> Típus váltás előtt menteni kell a beállított értékeket!
      </v-alert>

      <v-card>
        <v-data-table
          :loading="loading"
          :headers="dataTable.headers"
          :items="dataTable.items"
          :server-items-length="dataTable.itemsLength"
          :options.sync="dataTable.options"
          :footer-props="$config.dataTableFooterProps"
          :item-class="itemClass"
        >
          <template #[`item.groups`]="{ item }">
            <v-chip
              v-for="group in item.groups"
              :key="group.id"
              :color="group.color"
              class="mr-2"
              small
              label
              link
              :to="{ name: 'GroupEditor', params: { id: group.element_id } }"
              target="_blank"
            >
              {{ group.name }}
            </v-chip>
          </template>
          <template #[`item.users`]="{ item }">
            <v-autocomplete
              v-model="item.user_ids"
              :items="users"
              item-text="name"
              item-value="element_id"
              hide-details
              multiple
              chips
              filled
            />
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'Filter',
      fetchItems: this.fetchElements,
      elementTypeId: null,
      elementTypes: [],
      loading: false,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'ID', value: 'element_id' },
          { text: 'Megnevezés', value: 'name' },
          { text: 'Csoportok', value: 'groups', sortable: false },
          { text: 'Felhasználók', value: 'users', sortable: false },
        ],
      },
    };
  },

  watch: {
    elementTypeId() {
      this.fetchItems();
    },
  },

  methods: {
    async fetchElements() {
      if (!this.elementTypeId) {
        return;
      }
      this.loading = true;
      try {
        const response = await this.$http.post(
          `filter/elements/list/${this.elementTypeId}`,
          this.postData
        );
        this.dataTable.items = response.elements;
        this.dataTable.itemsLength = response.elements_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      this.loading = true;
      try {
        const response = await this.$http.post('filter/save', {
          elements: this.dataTable.items,
        });
        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          this.$asyncComputed.elements.update();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    elementTypes: {
      async get() {
        const response = await this.$http.get('filter/element-types/list');
        return response.element_types;
      },
      default: [],
    },

    users: {
      async get() {
        const response = await this.$http.post('users/list', { filter_element: true });
        return response.users;
      },
      default: [],
    },
  },
};
</script>
